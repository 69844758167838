<template>
  <div v-if="tags.length" class="case-study-tag-filter row h-end">
    <div class="case-study-tag-filter__wrap">
      <button class="case-study-tag-filter__current row v-center">
        <span>{{ currentTagName }}</span>
      </button>

      <ul class="case-study-tag-filter__list">
        <li class="case-study-tag-filter__item">
          <button
            class="case-study-tag-filter__tag"
            :class="activeTagClass('')"
            @click="tagReset()"
          >
            {{ label[lang as keyof typeof label] }}
          </button>
        </li>

        <li v-for="tag in tags" :key="tag" class="case-study-tag-filter__item">
          <button
            class="case-study-tag-filter__tag"
            :class="activeTagClass(tag)"
            @click="tagHandler(tag)"
          >
            {{ tag }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  selectedTag: string
  filteredTagList: Array<string>
  pageTagList: Array<string>
  lang: string
}>()

const label = {
  pl: 'Wszystkie',
  de: 'Alles',
  en: 'All'
}

const tags = computed(() =>
  props.pageTagList.filter((category) =>
    props.filteredTagList.includes(category)
  )
)

const currentTagName = computed(() => {
  const currentTag = tags.value.filter((tag) => tag === props.selectedTag)
  return currentTag.length
    ? currentTag[0]
    : label[props.lang as keyof typeof label]
})

const activeTagClass = (tag: string) =>
  tag === props.selectedTag ? 'case-study-tag-filter__tag--active' : ''

const emit = defineEmits(['select-tag', 'reset-tag'])
const tagHandler = (tag: string) => {
  emit('select-tag', tag)
}
const tagReset = () => {
  emit('reset-tag')
}
</script>

<style lang="scss">
.case-study-tag-filter {
  $base: &;

  padding: spacing(xs) 0;
  font-size: 14px;
  font-weight: 400;
  color: color(black);
  border-bottom: 1px solid color(wild-sand);

  &__wrap {
    position: relative;
    margin-bottom: 16px;
    margin-right: 16px;

    &:hover {
      & #{$base}__current {
        border-bottom-right-radius: 0;

        &::before {
          transform: translateX(0);
          opacity: 1;
        }

        &::after {
          transform: scaleY(-1);
        }
      }

      & #{$base}__list {
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__current {
    border: 2px solid color(primary);
    border-radius: 3px;
    background-color: transparent;
    height: 28px;
    color: color(primary);
    font-size: inherit;
    font-weight: 500;
    padding: 0 10px;
    cursor: pointer;
    min-width: 58px;
    max-width: 175px;

    & span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &::before {
      content: '';
      background-color: color(white);
      height: 2px;
      width: calc(100% - 4px);
      display: block;
      bottom: 0;
      right: 2px;
      position: absolute;
      z-index: z-index(over);
      transform: translateX(-105%);
      opacity: 0;
    }

    &::after {
      content: '';
      background-image: url('~ui/assets/icons/icon-arrow-down.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      filter: color(primary, $colors-filter);
      height: 40px;
      width: 20px;
      margin-left: 8px;
      flex-shrink: 0;
      transition: transform 0.25s;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    top: calc(100% - 2px);
    right: 0;
    min-width: 300px;
    background-color: color(white);
    border: 2px solid color(primary);
    border-radius: 3px;
    z-index: z-index(base);
    transform: scaleY(0);
    transform-origin: 0 0;
    visibility: hidden;
    opacity: 0;
    transition:
      transform 0.25s,
      visibility 0.25s,
      opacity 0.25s;
  }

  &__item {
    font-size: inherit;
  }

  &__tag {
    $tag: &;

    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: block;
    font-weight: inherit;
    color: inherit;
    padding: spacing(xs) spacing(md);
    text-align: left;
    width: 100%;
    transition: color 0.25s;

    &:hover,
    &--active {
      color: color(primary);
    }

    &--borders {
      padding: spacing(xs) spacing(sm);
      border: 1px solid currentColor;
      transition:
        background-color 0.25s,
        color 0.25s;

      &:hover,
      &#{$base}--active {
        color: color(white);
        background-color: color(primary);
      }
    }
  }
}
</style>
